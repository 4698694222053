import React from 'react';

const PresentationText = ({ iconClass, strongText, description }) => {
  return (
    <div className='row mb-3'>
      <div className='col-md-auto col-2'>
        <i className={iconClass}></i>
      </div>
      <div className='col'>
        <p className='laius-text m-0'><strong>{strongText}</strong> {description}</p>
      </div>
    </div>
  );
};

export default PresentationText;