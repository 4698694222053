import React, { useRef, useEffect } from 'react';

const Starfield = () => {
  const canvasRef = useRef(null);

  useEffect(() => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');

    canvas.width = window.innerWidth;
    canvas.height = window.innerHeight;

    let stars = [];
    const numStars = 200;
    const attractionRadius = 50;
    let mouseX = canvas.width / 2;
    let mouseY = canvas.height / 2;

    const createStars = () => {
      stars = [];
      for (let i = 0; i < numStars; i++) {
        const x = Math.random() * canvas.width;
        const y = Math.random() * canvas.height;
        const size = Math.random() * 2 + 1;
        const alpha = Math.random() * 0.3 + 0.1;
        const originalX = x;
        const originalY = y;
        const blinkPeriod = Math.random() * 1000 + 2000;
        const blinkStartTime = Math.random() * blinkPeriod;
        const orbitRadius = Math.random() * (50 - 20) + 20;
        const orbitAngle = Math.random() * Math.PI * 2;
        const orbitSpeed = 0.01 + Math.random() * 0.02;

        stars.push({ x, y, size, alpha, originalX, originalY, blinkPeriod, blinkStartTime, orbitRadius, orbitAngle, orbitSpeed });
      }
    };

    const drawStars = (currentTime) => {
      ctx.clearRect(0, 0, canvas.width, canvas.height);
      stars.forEach(star => {
        const elapsedTime = currentTime - star.blinkStartTime;
        const blinkProgress = (elapsedTime % star.blinkPeriod) / star.blinkPeriod;
        const isBlinking = blinkProgress < 0.5;

        ctx.globalAlpha = isBlinking ? star.alpha : star.alpha * 0.5;
        ctx.fillStyle = 'rgba(255, 255, 255, 1)';
        ctx.beginPath();
        ctx.arc(star.x, star.y, star.size, 0, Math.PI * 2);
        ctx.fill();
      });
    };

    const updateStars = () => {
      stars.forEach(star => {
        const dx = mouseX - star.x;
        const dy = mouseY - star.y;
        const distance = Math.sqrt(dx * dx + dy * dy);

        if (distance < attractionRadius) {
          const orbitCenterX = mouseX;
          const orbitCenterY = mouseY;

          const orbitX = Math.cos(star.orbitAngle) * star.orbitRadius;
          const orbitY = Math.sin(star.orbitAngle) * star.orbitRadius;

          star.x = orbitCenterX + orbitX;
          star.y = orbitCenterY + orbitY;

          star.orbitAngle += star.orbitSpeed;
        } else {
          const originalDx = star.originalX - star.x;
          const originalDy = star.originalY - star.y;
          const force = 0.05;
          star.x += originalDx * force;
          star.y += originalDy * force;
        }
      });
    };

    const handleResize = () => {
      canvas.width = window.innerWidth;
      canvas.height = window.innerHeight;
      createStars();
    };

    const handleMouseMove = (e) => {
      mouseX = e.clientX;
      mouseY = e.clientY;
    };

    window.addEventListener('resize', handleResize);
    canvas.addEventListener('mousemove', handleMouseMove);

    createStars();

    const animate = () => {
      updateStars();
      drawStars(performance.now());
      requestAnimationFrame(animate);
    };
    animate();

    return () => {
      window.removeEventListener('resize', handleResize);
      canvas.removeEventListener('mousemove', handleMouseMove);
    };
  }, []);

  return <canvas ref={canvasRef} className='starfield' style={{ display: 'block', backgroundColor: '#001d28' }} />;
};

export default Starfield;