import React, { useState } from 'react';
import '../../style/Card.css'; 

function Card({ image, title, inProgress, description, technologies, githubLink, webLink }) {
  const [isActive, setIsActive] = useState(false);

  const handleClick = () => {
    if(isActive){
      window.location.href = webLink;
    }
    setIsActive(!isActive);
  };

  const handleIsActive = () => {
    setIsActive(true);
  };

  const handleIsInactive = () => {
    setIsActive(false);
  };

  return (
    <div className="row">
      <div className="col"> 
        <div className={`card custom-card d-flex justify-content-center ${isActive ? 'active' : ''}`} >
          <div className='img-container'>
            <img src={image} className="img-card" alt={title}/>
          </div>
          <div className="card-body" onClick={handleClick} onMouseEnter={handleIsActive} onMouseLeave={handleIsInactive}>
            <div className='row h-100'>
              <div className='col flex-column d-flex justify-content-evenly'>
                <h3 className="card-title"><a className='primary' href={webLink}>{title}</a></h3>
                {inProgress && (<h3 className="progress-title px-5">En cours de développement</h3>)}
                {description && (<p className="card-text px-5">{description}</p>)}
                {githubLink && (<p className="card-text px-5"><a className='primary' href={githubLink}>Github</a></p>)}
                
                {technologies && (<div className="tech-icons justify-content-center">
                  {technologies.map((tech, index) => (
                    <img key={index} src={tech.src} alt={tech.alt} className='tech-icon' /> ))}
                </div>)}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Card;