import React, { useState, useEffect } from 'react';
import '../../../style/Quiz.css';

const questions = [
  {
    question: "À combien de battements par minute bat le coeur d'un colibri ?",
    answers: ["120 bpm", "500 bpm", "1200 bpm", "300 bpm"],
    correct: "1200 bpm",
    alert: "Bravo, quelle culture impressionnante !"
  },
  {
    question: "Quelle distance totale ai-je parcourue en course à pied ?",
    answers: ["150 km", "256 km", "409 km", "350 km"],
    correct: "409 km",
    alert: "Vous me flattez"
  },
  {
    question: "Quel projet ai-je réalisé ?",
    answers: ["Générateur d'insultes", "Jeux basés sur les mots", "Générateur de compliments", "Apollo 13"],
    correct: "Jeux basés sur les mots",
    alert:"C'est juste"
  },
  {
    question: "Quel aspect de la culture d'entreprise de Walaaxy m'attire le plus ?",
    answers: ["La possibilité de travailler à distance", "L'accent mis sur la collaboration et l'innovation", "Les pauses café prolongées", "Les soirées jeux de société"],
    correct: "L'accent mis sur la collaboration et l'innovation",
    alert: "En réalité, tout aurait pu être vrai"
  },
];


function Quiz() {
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [selectedAnswer, setSelectedAnswer] = useState("");
  const [score, setScore] = useState(0);
  const [showScore, setShowScore] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [showAlert, setShowAlert] = useState(false);
  const [buttonPosition, setButtonPosition] = useState({ top: 0, left: 0 });
  const [moveDirection, setMoveDirection] = useState(0); // 0: Droite, 1: Haut, 2: Gauche, 3: Bas
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener('resize', handleResize);
    handleResize(); // Initial check

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const handleAnswerOptionClick = (answer) => {
    setSelectedAnswer(answer);
    setButtonPosition({ top: 0, left: 0 }); // Réinitialiser la position du bouton
    document.querySelector('#submit-quizz-btn').classList.remove('d-none');    
    if (isMobile && answer !== questions[currentQuestion].correct) {
      document.querySelector('#submit-quizz-btn').classList.add('d-none');
    }
  };

  const handleSubmit = () => {
    let message = "";
    if (selectedAnswer === questions[currentQuestion].correct) {
      setScore(score + 1);
      message = questions[currentQuestion].alert;
    } else {
        return;
    }
    setAlertMessage(message);
    setShowAlert(true);

    setTimeout(() => {
      setShowAlert(false);
      const nextQuestion = currentQuestion + 1;
      if (nextQuestion < questions.length) {
        setCurrentQuestion(nextQuestion);
        setSelectedAnswer("");
      } else {
        setShowScore(true);
      }
    }, 2000); // Affiche l'alerte pendant 2 secondes
  };

  const handleMouseMove = (e) => {
    if (selectedAnswer !== questions[currentQuestion].correct) {
      const button = e.target;
      const rect = button.getBoundingClientRect();
      const viewportWidth = window.innerWidth;
      const viewportHeight = window.innerHeight;
      const distance = Math.sqrt(
        Math.pow(e.clientX - (rect.left + rect.width / 2), 2) +
        Math.pow(e.clientY - (rect.top + rect.height / 2), 2)
      );

      if (distance < 100) {
        let newTop = buttonPosition.top;
        let newLeft = buttonPosition.left;

        if (isMobile) {
          // Déplacer le bouton de 50px vers le haut et vers le bas
          switch (moveDirection) {
            case 0: // Bas
              newTop += 50;
              break;
            case 1: // Haut
              newTop -= 50;
              break;
            default:
              break;
          }
          setMoveDirection((moveDirection + 1) % 2);
        } else {
          // Déplacer le bouton de manière cyclique
          switch (moveDirection) {
            case 0: // Droite
              newLeft += 200;
              break;
            case 1: // Haut
              newTop -= 200;
              break;
            case 2: // Gauche
              newLeft -= 200;
              break;
            case 3: // Bas
              newTop += 200;
              break;
            default:
              break;
          }
          setMoveDirection((moveDirection + 1) % 4);
        }

        // Limiter le mouvement du bouton à l'intérieur de la section
        if (newTop < 0) newTop = 0;
        if (newTop + rect.height > viewportHeight) newTop = viewportHeight - rect.height;
        if (newLeft < 0) newLeft = 0;
        if (newLeft + rect.width > viewportWidth) newLeft = viewportWidth - rect.width;

        setButtonPosition({ top: newTop, left: newLeft });
      }
    }
  };
  return (
    <section id="quiz" className='row mb-5 gx-0'>
      <div className='col-10 mx-auto d-flex flex-column mt-5'>
        <div className='row mb-5 mb-md-0 title'>
          <div className='col d-flex align-items-center justify-content-center'>
            <h1 className='presentation-title'>
              It's Quiz time
            </h1>
          </div>
        </div>
        <div className='row mb-5 quiz-container'>
          {showScore ? (
            <div className='col d-flex align-items-center justify-content-center'>
              <h2>C'est un match !</h2>
            </div>
          ) : (
            <div className='col'>
                <div className='row mb-4'>
                    <h2>{questions[currentQuestion].question}</h2>
                </div>
                <div className='row mb-4'>
                    {questions[currentQuestion].answers.map((answer, index) => (
                    <div key={index} className="col-md-6 col-12 form-check">
                        <input
                        className="form-check-input"
                        type="radio"
                        name="answer"
                        id={`answer${index}`}
                        value={answer}
                        checked={selectedAnswer === answer}
                        onChange={() => handleAnswerOptionClick(answer)}
                        />
                        <label className="form-check-label" htmlFor={`answer${index}`}>
                        {answer}
                        </label>
                    </div>
                    ))}
                </div>
                              <div className='row align-items-center'>
                <div className='col-auto'>
                  <button
                    id='submit-quizz-btn'
                    className='btn btn-success'
                    onClick={handleSubmit}
                    disabled={!selectedAnswer}
                    style={{ position: 'relative', top: buttonPosition.top, left: buttonPosition.left }}
                    onMouseMove={handleMouseMove}
                  >
                    Répondre
                  </button>
                </div>
                <div className='col message-quiz'>
                  {showAlert && (
                    <span>
                      {alertMessage}
                    </span>
                  )}
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </section>
  );
}

export default Quiz;