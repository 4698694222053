import Carousel from 'react-bootstrap/Carousel';
import nodeJs from '../../../images/node.jpg';
import react from '../../../images/react.png';
import postgre from '../../../images/postgre.png';
import projetTarot from '../../../images/tarot.jpg';
import projetDesMots from '../../../images/trotro.jpg';
import '../../../style/Projets.css';
import Card from '../../common/Card';
function Projets(){
  const technologies = [
      {src: nodeJs, alt:"NodeJs"},
      {src: react, alt:"React"},
      {src: postgre, alt:"PostgreSQL"},
    ];
  const technologiesJeuxDesMots = [
    {src: react, alt:"React"}
  ]
  return(
      <section id="projet" className="row mt-3 gx-0">
        <div className='row mt-5 '>
          <div className='col d-flex align-items-center justify-content-center'>
            <h1 className='presentation-title'>
              Projets
            </h1>
          </div>
        </div>
        <div className='row'>
          <Carousel>
            <Carousel.Item>
              <div className='slider-item'>
                <div className='card-container'>
                  <Card 
                    image={projetTarot}
                    inProgress={true}
                    title='Gestion de parties de tarot'
                    description='Application web et mobile permettant de gérer des parties de tarot, comptabiliser les points, historiser, et analyser les résultats.'
                    webLink={'https://tarot.loicmestre.fr/'}
                    technologies={technologies}
                    />
                </div>
              </div>
              <div className='row mt-3 justify-content-center align-items-center mx-auto'>
                <div className='col-auto'>
                  <h1><a className='secondary' href="https://tarot.loicmestre.fr/">Gestion de parties de tarot</a></h1>
                </div>
              </div>
            </Carousel.Item>
            <Carousel.Item>
              <div className='slider-item'>
                <div className='card-container'>
                  <Card 
                    image={projetDesMots}
                    title='Jeux des mots'
                    inProgress={false}
                    description='Application web et mobile permettant de jouer à plusieurs jeux autour des mots. (Tusmo, Mot2Passe,...)'
                    webLink={'https://desmots.loicmestre.fr/'}
                    technologies={technologiesJeuxDesMots}
                    />
                </div>
              </div>
              <div className='row mt-3 justify-content-center align-items-center mx-auto'>
                <div className='col-auto'>
                  <h1><a className='secondary' href='https://desmots.loicmestre.fr/'>Jeux des mots</a></h1>
                </div>
              </div>
            </Carousel.Item>
          </Carousel>
        </div>
    </section>
  );
}



export default Projets;