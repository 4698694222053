import React from 'react';
import PresentationText from './PresentationText'; // Assurez-vous que le chemin est correct
import '../../style/Profile.css';
const BandeauPresentation = ({ profilePicture }) => {
  return (
    <>
    <div className="row justify-content-center position-relative d-none d-lg-flex mb-4">
      <div className='col-auto profile-picture-section h-100 align-content-center position-absolute'>
        <img src={profilePicture} alt="Présentation" className='profile-picture'/>
      </div>
        <div className='col laius-presentation left me-3'>
          <PresentationText 
            iconClass="fa-solid fa-laptop" 
            strongText="Développeur passionné" 
            description="par le développement web et les nouvelles technologies" 
          />
          <PresentationText 
            iconClass="fa-solid fa-bullseye" 
            strongText="En quête constante de nouveaux défis" 
            description="pour approfondir mes compétences en UX et design" 
          />
          <PresentationText 
            iconClass="fa-solid fa-lightbulb" 
            strongText="Enthousiaste, créatif et curieux," 
            description="je m'épanouis dans des environnements où l'innovation et la collaboration priment" 
          />
        </div>
        <div className='col ms-3 laius-presentation right'>
          <PresentationText 
            iconClass="fa-solid fa-users" 
            strongText="Approche centrée sur l'utilisateur : " 
            description="créer des expériences intuitives et engageantes" 
          />
          <PresentationText 
            iconClass="fa-solid fa-star" 
            strongText="Priorité à la qualité du code " 
            description="et amélioration continue de mes compétences techniques" 
          />
          <PresentationText 
            iconClass="fa-solid fa-arrow-up-right-dots" 
            strongText="Autonome et ambitieux, " 
            description="je cherche à maximiser mon impact en identifiant les actions clés qui produisent des résultats significatifs" 
          />
        </div>
    </div>
    <div className='row justify-content-center position-relative d-lg-none profile-picture-container'>
      <div className='col-auto profile-picture-section'>
        <img src={profilePicture} alt="Présentation" className='profile-picture'/>
      </div>
    </div>
    <div className='row small-device d-lg-none mb-4'>
        <div className='col laius-presentation'>
        <PresentationText 
            iconClass="fa-solid fa-laptop" 
            strongText="Développeur passionné" 
            description="par le développement web et les nouvelles technologies" 
          />
          <PresentationText 
            iconClass="fa-solid fa-bullseye" 
            strongText="En quête constante de nouveaux défis" 
            description="pour approfondir mes compétences en UX et design" 
          />
          <PresentationText 
            iconClass="fa-solid fa-lightbulb" 
            strongText="Enthousiaste, créatif et curieux," 
            description="je m'épanouis dans des environnements où l'innovation et la collaboration priment" 
          />
          <PresentationText 
            iconClass="fa-solid fa-users" 
            strongText="Approche centrée sur l'utilisateur : " 
            description="créer des expériences intuitives et engageantes" 
          />
          <PresentationText 
            iconClass="fa-solid fa-star" 
            strongText="Priorité à la qualité du code " 
            description="et amélioration continue de mes compétences techniques" 
          />
          <PresentationText 
            iconClass="fa-solid fa-arrow-up-right-dots" 
            strongText="Autonome et ambitieux, " 
            description="je cherche à maximiser mon impact en identifiant les actions clés qui produisent des résultats significatifs" 
          />
      </div>
    </div>
    
    </>
  );
};

export default BandeauPresentation;