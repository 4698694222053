import BandeauPresentation from '../common/BandeauPresentation.js';
import profilePicture from '../../images/profile_picture.jpeg';
import LandingPageStats from '../common/LandingPageStats.js';
import waalaxyLogo from '../../images/waalaxy.png';
import PresentationText from '../common/PresentationText.js';
import Starfield from '../common/Starfield.js';
import Projets from './Projects/index.js';
import Quiz from './Quiz/index.js';

const statsData = [
  { number: "24", title: "Années d'expérience de la vie humaine"},
  { number: "17", title: "Gâteaux réalisés"},
  { number: "409,6", title: "km parcourus en course à pieds"},
  { number: "42", title: "tasses de café consommées pour rester productif" },
  { number: "51200", title: "lignes de code cette année" }
];

const WaalaxyStats = [
    {number: "150", title: "plantes dans le grand open space"},
    {number: "3", title: "années pour être premier mondial"},
    {number: "11", title: "langues disponibles du produit"},
    {number: "200 000", title: "utilisateurs visés en 2024"},
    {number: "1", title: "piscine à boules"},
]

const Motivation = function(){
    return (
    <>
        <Starfield/>
        <section id="motivation" className='row gx-0'>
            <div className='col-10 mx-auto'>
                <div className='row title mt-2 mb-lg-0 mb-4'>
                    <div className='col d-flex align-items-center justify-content-center'>
                        <h1 className='presentation-title'>
                        <span className='first-name'>Loïc</span><span className='last-name'> Mestre</span>
                        </h1>
                    </div>
                </div>
                <BandeauPresentation profilePicture={profilePicture} />
                <LandingPageStats stats={statsData} dataGroup="presentation"/>
            </div>
        </section>
            
        <Projets />
        <section id="waalaxy" className='row mb-5 gx-0'>
            <div className='col-10 mx-auto d-flex flex-column justify-content-evenly'>
                <div className='row mb-5 mb-md-0 title'>
                    <div className='col d-flex align-items-center justify-content-center'>
                        <h1 className='presentation-title'>
                        Waalaxy
                        </h1>
                    </div>
                </div>
                <div className='row mb-5'>
                    <div className='col-lg-10 col mx-auto position-relative'>
                        <div className='row position-absolute waalaxy-logo-container'>
                            <div className='col-auto position-relative '>
                                <img className='profile-picture' src={waalaxyLogo}></img>
                            </div>
                        </div>
                        <div className='row d-flex justify-content-center align-items-center '>
                            <div className='col-md-10 col laius-presentation position-relative px-lg-5'>
                                <div className='row'>
                                    <div className='col-1 d-lg-block d-none'>
                                    </div>
                                    <div className='col ps-lg-5'>
                                    <PresentationText 
                                        iconClass="fa-solid fa-lightbulb" 
                                        strongText="Start-up innovante" 
                                        description=" : Spécialisée dans les solutions SaaS de prospection automatisée pour LinkedIn et e-mail" 
                                        />
                                    <PresentationText 
                                        iconClass="fa-solid fa-gauge-high" 
                                        strongText="Croissance Rapide" 
                                        description=" : Leader mondial sans levée de fonds, avec une expansion fulgurante en trois ans." 
                                    />
                                    <PresentationText 
                                        iconClass="fa-solid fa-users" 
                                        strongText="Accessibilité et Simplicité" 
                                        description=" : Focalisée sur la simplicité d'utilisation pour tous les utilisateurs, même sans compétences techniques." 
                                        />  
                                    <PresentationText
                                        iconClass="fa-solid fa-brain"
                                        strongText="Environnement de travail stimulant"
                                        description=" : Encourage l'innovation et la collaboration, avec une équipe passionnée et créative, pour des solutions toujours plus performantes."/>
                                    <PresentationText
                                    iconClass="fa-solid fa-arrow-up-right-dots"
                                    strongText="Objectifs ambitieux"
                                    description=" : Vise à rendre la prospection accessible à 200 000 utilisateurs d'ici à 2024."></PresentationText>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='container'>
                    <div className='align-items-center justify-content-center d-flex row'>
                        <LandingPageStats stats={WaalaxyStats} dataGroup="waalaxy"/>
                    </div>
                </div>
            </div>
        </section>
        <Quiz/>
    </>
    );
}

export default Motivation;