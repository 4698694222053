import React, { useEffect, useRef, useState } from 'react';
import '../../style/NumberHighligh.css';

const NumberHighlight = ({ number, title, dataGroup}) => {
  const finalNumber = typeof number === 'string' ? parseFloat(number) : number;
  const [currentNumber, setCurrentNumber] = useState(0);
  const numberRef = useRef(null); // Créez une référence pour le composant

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        // Pour chaque entrée, vérifiez si l'élément est visible
        entries.forEach(entry => {
          if (entry.isIntersecting) {
            // Déclenchez l'animation ici
            let startNumber = 0;
            const duration = 1000; // Durée de l'animation en millisecondes
            const intervalTime = 20; // Temps entre chaque incrément en millisecondes
            const totalIncrements = duration / intervalTime;
            const increment = (finalNumber - startNumber) / totalIncrements;

            entry.target.classList.remove('not-visible');
            const group = entry.target.getAttribute('data-group');
            const allInGroup = Array.from(document.querySelectorAll(`.row.card-number[data-group="${group}"]`));
            const index = allInGroup.indexOf(entry.target);
            const delayClass = `delay-${(index % 5) + 1}`; 
            entry.target.classList.add(delayClass);
            
            const interval = setInterval(() => {
              startNumber += increment;
              if (startNumber < finalNumber) {
                setCurrentNumber(startNumber);
              } else {
                clearInterval(interval);
                setCurrentNumber(finalNumber); // Assurez-vous d'atteindre la valeur finale
              }
            }, intervalTime);
            entry.target.classList.add('slideFromMiddleToBottom');

            // Une fois l'animation déclenchée, désinscrivez l'observateur pour cet élément
            observer.unobserve(entry.target);
          }
        });
      },
      { threshold: 0.1, rootMargin:'0px' } // Configurez le seuil de visibilité pour déclencher l'animation
    );

    // Attachez l'observateur à la référence du composant
    if (numberRef.current) {
      observer.observe(numberRef.current);
    }

    // Nettoyez l'observateur lorsque le composant est démonté
    return () => {
      if (numberRef.current) {
        observer.disconnect();
      }
    };
  }, [finalNumber]);
  return (
    <div ref={numberRef} data-group={dataGroup} className="row card-number gx-0 not-visible">
        <div className='col text-center'>
            <div className='row'>    
                <div className='col'>
                    <h2 className='title-card'>{Math.round(currentNumber)}</h2>
                </div>
            </div>
            <div className='row'>        
                <div className='col'>
                    <p className='text-card'>{title}</p>
                </div>
            </div>
        </div>
    </div>
  );
};

export default NumberHighlight;