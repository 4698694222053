import React from 'react';
import NumberHighlight from './NumberHighlight';

const LandingPageStats = ({ stats, dataGroup }) => {
  return (
    <div className='row justify-content-lg-around mx-auto gx-0'>
        {stats.map((stat, index) => (
          <div key={index} className='col-lg-auto col-md-4 col-12 mb-3'>
            <NumberHighlight number={stat.number} title={stat.title} isInteger={stat.isInteger} dataGroup={dataGroup} />
          </div>
        ))}
    </div>
  );
};

export default LandingPageStats;